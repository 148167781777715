<template>
  <Card
    class="c-card--pa0 c-card--mile"
    :radius="12"
    link
    :tiled="tiled"
    :noticed="noticed">
    <a
      :href="href"
      :style="!isLinked ? 'pointer-events: none' : ''"
      v-ripple="isLinked">
      <div class="c-card__inner">
        <div class="c-card__img c-card__img--lg">
          <img v-if="src" :src="src" :alt="title" />
          <NoImage v-else />
        </div>
        <!-- /.c-card__img -->

        <div class="c-card__content">
          <div class="c-card__subtitle2">
            {{ subtitle }}
          </div>
          <!-- /.c-card__subtitle2 -->
          <div class="c-card__title2">
            {{ title }}
          </div>
          <!-- /.c-card__title2 -->
          <div class="c-card__bottom">
            <slot />
          </div>
          <!-- /.c-card__bottom -->
        </div>
        <!-- /.c-card__content -->
      </div>
      <!-- /.c-card__inner -->
    </a>
  </Card>
</template>

<script>
export default {
  props: {
    href: {
      type: String,
      default: ''
    },

    src: {
      type: String,
      default: ''
    },

    title: {
      type: String,
      default: ''
    },

    subtitle: {
      type: String,
      default: ''
    },

    tiled: {
      type: Boolean,
      default: false
    },

    noticed: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    isLinked() {
      return this.href.length > 0;
    }
  }
};
</script>
